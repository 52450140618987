<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="home">
        <EventComponent/>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import EventComponent from '@/components/EventComponent.vue'

export default {
  name: 'Contest',
  components: {
    EventComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
